import React from "react";

import "../../components/all.sass";

const Loading = () => {
  return (
    <div>
      <meta name="robots" content="noindex" />
      <div className="hero is-fullheight is-info is-flex is-flex-direction-column is-justify-content-center">
        <div className="section">
          <div className="container">
            <progress className="progress" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
